import React from "react";
import Header from "../components/header";

export default class ErrorPage extends React.Component<any, any> {

	constructor(props) {
		super(props);
	}

	public render() {

		return(
			<React.Fragment>
				<Header>
					Sorry Not Found
				</Header>
			</React.Fragment>
		);

	}
}